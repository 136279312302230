export const assignmentStatuses = {
  Hall: 'Зал',
  Pickup: 'Самовывоз',
  Delivery: 'Доставка',
  Staff: 'Питание',
  InDelivery: 'Доставляется',
}

export const orderItemTypes = {
  pizza: 'Пицца',
  snack: 'Закуски',
  drink: 'Напитки',
}
