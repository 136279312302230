import React, { useState, useEffect } from 'react'
import { useDataProvider, useListFilterContext } from 'react-admin'
import { Line } from 'react-chartjs-2'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    margin: '1em 0',
  },
})

const Statistics = () => {
  const [graphData, setGraphData] = useState(null)
  const dataProvider = useDataProvider()

  const {
    filterValues: { place, dateStart },
  } = useListFilterContext()

  useEffect(() => {
    if (dateStart && place) {
      dataProvider
        .getRequest(
          `daily_stats_graphs_by_day?dateStart=${+new Date(
            dateStart
          )}&place=${place}`
        )
        .then((resp) => setGraphData(resp.data))
        .catch((error) => console.error(error))
    }
  }, [place, dateStart])

  const renderDateText = () => {
    if (!dateStart) {
      return null
    }

    return (
      <Typography>
        Скорость выполнения заказов за{' '}
        {new Date(dateStart).toLocaleDateString()}
      </Typography>
    )
  }

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        {renderDateText()}
        {graphData && <Line data={graphData} />}
      </CardContent>
    </Card>
  )
}

export default Statistics
