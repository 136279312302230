import React from 'react'
import { Redirect } from 'react-router-dom'
import { Resource } from 'react-admin'

import SettingsIcon from '@material-ui/icons/Settings'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import PlaceIcon from '@material-ui/icons/Place'
import StorageIcon from '@material-ui/icons/Storage'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import SubjectIcon from '@material-ui/icons/Subject'
import LocalPizzaIcon from '@material-ui/icons/LocalPizza'
import TimerIcon from '@material-ui/icons/Timer'
import PeopleIcon from '@material-ui/icons/People'
import StoreIcon from '@material-ui/icons/Store'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import cityProps from './city'
import placesProps from './place'
import inventoryProps from './inventory'
import inventoryIngredientsProps from './inventory_ingredients'
import inventoryIngredientProps from './inventory_ingredient'
import storageProps from './storage'
import orderItemsTypeProps from './order_items_type'
import orderItemsTypeChecksProps from './order_items_type_checks'
import movementsProps from './movements'
import dailyStatsProps from './daily_stats'
import dailyStatsByDayProps from './daily_stats_by_day'
import salesOrderItemsProps from './sales_order_items'
import salesOrdersByTypeProps from './sales_orders_by_type'
import salesPizzasByDiameters from './sales_pizzas_by_diameters'
import salesOrdersByTimeProps from './sales_orders_by_time'
import salesByTypesAndTime from './sales_by_types_and_time'
import suppliesProps from './supplies'
import suppliersProps from './suppliers'
import suppliersOrdersProps from './supplies_orders'
import adminProps from './admin'
import averageByIngredientsProps from './average_by_ingredients'
import posterUsersProps from './poster_users'
import posterUsersRatingsProps from './poster_users_rating'

const adminRoutes = [
  <Resource
    name="menu_settings"
    options={{ isMenuParent: true }}
    icon={SettingsIcon}
    permissions="admin"
  />,
  <Resource
    name="city"
    {...cityProps}
    options={{ menuParent: 'menu_settings' }}
    icon={LocationCityIcon}
    permissions="admin"
  />,
  <Resource
    name="places"
    {...placesProps}
    options={{ menuParent: 'menu_settings' }}
    icon={PlaceIcon}
    permissions="admin"
  />,
  <Resource
    name="storage"
    {...storageProps}
    options={{ menuParent: 'menu_settings' }}
    icon={StorageIcon}
    permissions="admin"
  />,
  <Resource
    name="admin"
    {...adminProps}
    options={{ menuParent: 'menu_settings' }}
    icon={PeopleIcon}
    permissions="admin"
  />,
]

const supervisorRoutes = [
  <Resource name="places" permissions="supervisor" />,
  <Resource name="storage" permissions="supervisor" />,
]

const mainRoutes = [
  <Resource name="categories" options={{ hidden: true }} />,
  <Resource
    name="poster_users"
    key="poster_users"
    {...posterUsersProps}
    icon={PeopleIcon}
  />,
  <Resource
    name="poster_user_ratings"
    key="poster_user_ratings"
    {...posterUsersRatingsProps}
    icon={PeopleIcon}
  />,
  <Resource
    name="statistics"
    options={{ isMenuParent: true }}
    icon={EqualizerIcon}
  />,
  <Resource
    name="inventory"
    {...inventoryProps}
    options={{ menuParent: 'statistics' }}
  />,
  <Resource
    name="inventory_ingredients"
    {...inventoryIngredientsProps}
    options={{ menuParent: 'statistics', hidden: true }}
  />,
  <Resource
    name="inventory_ingredients_info"
    {...inventoryIngredientProps}
    options={{ menuParent: 'statistics', hidden: true }}
  />,
  <Resource
    name="movements"
    {...movementsProps}
    options={{ menuParent: 'statistics' }}
  />,
  <Resource
    name="daily_stats"
    {...dailyStatsProps}
    options={{ menuParent: 'statistics' }}
  />,
  <Resource
    name="daily_stats_by_day"
    {...dailyStatsByDayProps}
    options={{ menuParent: 'statistics', hidden: true }}
  />,
  <Resource
    name="average_by_ingredients"
    {...averageByIngredientsProps}
    options={{ menuParent: 'statistics' }}
  />,

  <Resource name="sales" options={{ isMenuParent: true }} icon={SubjectIcon} />,
  <Resource
    name="order_items_type"
    {...orderItemsTypeProps}
    options={{ menuParent: 'sales' }}
  />,
  <Resource
    name="order_items_type_checks"
    {...orderItemsTypeChecksProps}
    options={{ menuParent: 'sales' }}
  />,
  <Resource
    name="sales_order_items"
    {...salesOrderItemsProps}
    options={{ menuParent: 'sales' }}
  />,
  <Resource
    name="sales_orders_by_type"
    {...salesOrdersByTypeProps}
    options={{ menuParent: 'sales' }}
  />,
  <Resource
    name="sales_pizzas_by_diameters"
    {...salesPizzasByDiameters}
    options={{ menuParent: 'sales' }}
    icon={LocalPizzaIcon}
  />,
  <Resource
    name="sales_orders_by_time"
    {...salesOrdersByTimeProps}
    options={{ menuParent: 'sales' }}
    icon={TimerIcon}
  />,
  <Resource
    name="sales_by_types_and_time"
    {...salesByTypesAndTime}
    options={{ menuParent: 'sales' }}
    icon={TimerIcon}
  />,

  <Resource
    name="all_supplies"
    options={{ isMenuParent: true }}
    icon={StoreIcon}
  />,
  <Resource
    name="supplies"
    {...suppliesProps}
    options={{ menuParent: 'all_supplies' }}
  />,
  <Resource
    name="suppliers"
    {...suppliersProps}
    options={{ menuParent: 'all_supplies' }}
    icon={GroupAddIcon}
  />,
  <Resource
    name="supplies_orders"
    {...suppliersOrdersProps}
    options={{ menuParent: 'all_supplies' }}
    icon={ShoppingCartIcon}
  />,
]

export const allSupervisorRoutes = [...supervisorRoutes, ...mainRoutes]

export const fetchRoutesByPermissions = (permissions) => {
  if (!permissions) {
    return [<Redirect to="/" />]
  }

  const { role, accessAllRoutes, accessRoutes } = permissions

  if (role === 'admin') {
    return [...adminRoutes, ...mainRoutes]
  }

  if (accessAllRoutes) {
    return allSupervisorRoutes
  }

  return allSupervisorRoutes.filter((resource) =>
    accessRoutes.includes(resource.props.name)
  )
}
