import React from 'react'
import { Admin } from 'react-admin'

import dataProvider from './providers/DataProvider'
import authProvider from './providers/AuthProvider'
import i18nProvider from './providers/I18nProvider'

import { fetchRoutesByPermissions } from './resources'
import AdminLayout from './layout/AdminLayout'
import AdminDashboard from './layout/AdminDashboard'

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      title="MokoIS Статистика"
      layout={AdminLayout}
      dashboard={AdminDashboard}
      authProvider={authProvider}
    >
      {fetchRoutesByPermissions}
    </Admin>
  )
}

export default App
