import React, { useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItemLink,
  getResources,
  useTranslate,
  DashboardMenuItem,
} from 'react-admin'
import { useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'
import DefaultIcon from '@material-ui/icons/ViewList'
import CustomMenuItem from './CustomMenuItem'

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em',
      },
    },
  }),
  { name: 'RaTreeMenu' }
)

const Menu = (props) => {
  const {
    className,
    dense,
    hasDashboard,
    onMenuClick = () => null,
    logout,
    ...rest
  } = props

  /**
   * Initialising the initialExpansionState and
   * active parent resource name at the time of
   * initial menu rendering.
   */
  const initialExpansionState = {}
  let parentActiveResName = null

  const [state, setState] = useState(initialExpansionState)
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const classes = useStyles(props)
  const translate = useTranslate()
  const open = useSelector(
    (prevOpenState) => prevOpenState.admin.ui.sidebarOpen
  )
  const pathname = useSelector(
    (prevOpenPathname) => prevOpenPathname.router.location.pathname
  )
  const resources = useSelector(getResources, shallowEqual)
  const visibleResources = resources.filter((res) => !res.options.hidden)
  const hasList = (resource) => resource.hasList

  const handleToggle = (parent) => {
    /**
     * Handles toggling of parents dropdowns
     * for resource visibility
     */
    setState((prevState) => {
      return { [parent]: !prevState[parent] }
    })
  }

  const isParent = (resource) =>
    /**
     * Check if the given resource is a parent
     * i.e. dummy resource for menu parenting
     */
    resource.options &&
    Object.prototype.hasOwnProperty.call(resource.options, 'isMenuParent') &&
    resource.options.isMenuParent

  const isOrphan = (resource) =>
    /**
     * Check if the given resource is an orphan
     * i.e. has no parents defined. Needed as
     * these resources are supposed to be rendered
     * as is
     *
     */
    resource.options &&
    !Object.prototype.hasOwnProperty.call(resource.options, 'menuParent') &&
    !Object.prototype.hasOwnProperty.call(resource.options, 'isMenuParent')

  const isChildOfParent = (resource, parentResource) =>
    /**
     * Returns true if the given resource is the
     * mapped child of the parentResource
     */
    resource.options &&
    Object.prototype.hasOwnProperty.call(resource.options, 'menuParent') &&
    resource.options.menuParent === parentResource.name

  const MenuItem = (resource) => (
    /**
     * Created and returns the MenuItemLink object component
     * for a given resource.
     */
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) ||
        translate(`resources.${resource.name}.menu`)
      }
      leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
    />
  )

  /**
   * Mapping a "parent" entry and then all its children to the "tree" layout
   */
  const mapParentStack = (parentResource) => (
    <CustomMenuItem
      key={parentResource.name}
      handleToggle={() => handleToggle(parentResource.name)}
      isOpen={
        state[parentResource.name] ||
        parentActiveResName === parentResource.name
      }
      sidebarIsOpen={open}
      name={
        parentResource.options.label || `resources.${parentResource.name}.menu`
      }
      icon={parentResource.icon ? <parentResource.icon /> : <LabelIcon />}
      dense={dense}
    >
      {
        // eslint-disable-next-line
        visibleResources
          .filter(
            (resource) =>
              isChildOfParent(resource, parentResource) && hasList(resource)
          )
          .map((childResource) => {
            return MenuItem(childResource)
          })
      }
    </CustomMenuItem>
  )

  /**
   * Mapping independent (without a parent) entries
   */
  const mapIndependent = (independentResource) =>
    hasList(independentResource) && MenuItem(independentResource)

  /**
   * Initialise all parents to inactive first.
   * Also find the active resource name.
   */
  visibleResources.forEach((resource) => {
    if (isParent(resource)) {
      initialExpansionState[resource.name] = false
    } else if (
      pathname.startsWith(`/${resource.name}`) &&
      Object.prototype.hasOwnProperty.call(resource.options, 'menuParent')
    ) {
      parentActiveResName = resource.options.menuParent
    }
  })

  /**
   * The final array which will hold the array
   * of resources to be rendered
   */
  const resRenderGroup = []

  /**
   * Looping over all resources and pushing the menu tree
   * for rendering in the order we find them declared in
   */
  visibleResources.forEach((r) => {
    if (isParent(r)) resRenderGroup.push(mapParentStack(r))
    if (isOrphan(r)) resRenderGroup.push(mapIndependent(r))
  })

  return (
    <div>
      <div className={classnames(classes.main, className)} {...rest}>
        {hasDashboard && (
          <DashboardMenuItem
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        )}
        {resRenderGroup}
        {isXSmall && logout}
      </div>
    </div>
  )
}

export default Menu
