const { NODE_ENV } = process.env

const API_SETTINGS = {
  host:
    NODE_ENV === 'development'
      ? 'http://127.0.0.1:3003/v1'
      : 'https://stats.mokopizza.org/api/v1',
}

export default API_SETTINGS
