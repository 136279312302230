import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  BooleanField,
  Filter,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

const StorageListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const StorageList = (props) => (
  <List {...props} filters={<StorageListFilters />} exporter={false}>
    <Datagrid>
      <TextField source="storageName" />
      <TextField source="storageAdress" />
      <ReferenceField source="place" reference="places">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" />
      <EditButton />
    </Datagrid>
  </List>
)

export default StorageList
