import React from 'react'
import { Link } from 'react-router-dom'

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  TextInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InventoryInfo from './components/InventoryInfo'
import ExportButton from '../shared/ExportButton'
import { isPermissionExist } from '../../routes'

const InventoryIngredientsListFilter = (props) => {
  const {
    filterValues: { inventory },
  } = props || { filterValues: {} }

  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
      <ExportButton alwaysOn inventory={inventory} key="inventory" />
    </Filter>
  )
}

const ViewButton = (props) => {
  const { record } = props

  if (!record) {
    return null
  }

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/inventory_ingredients_info',
        search: `filter=${JSON.stringify({
          itemId: record.itemId,
          inventory: record.inventory,
        })}`,
      }}
    >
      <VisibilityIcon />
    </Button>
  )
}

const InventoryIngredientsList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<InventoryIngredientsListFilter />}
      pagination={false}
      bulkActionButtons={false}
      perPage={1000}
      exporter={false}
    >
      <>
        <InventoryInfo />
        <Datagrid>
          <TextField source="item" />
          <NumberField source="startrest" />
          <NumberField source="income" />
          <NumberField source="charges" />
          <NumberField source="writeoff" />
          <NumberField source="writeoffcurrency" />
          <NumberField source="estimatedrest" />
          <NumberField source="factrest" />
          <NumberField source="factRestSum" />
          <NumberField source="difference" />
          <NumberField source="diffcurrency" />
          <NumberField source="chargesPercent" />
          {isPermissionExist(permissions, 'inventory_ingredients_info') && (
            <ViewButton />
          )}
        </Datagrid>
      </>
    </List>
  )
}

export default InventoryIngredientsList
