import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from 'ra-language-russian'
import additionalMessages from '../i18n'

export default polyglotI18nProvider(
  () => ({
    ra: {
      ...russianMessages.ra,
      navigation: { ...russianMessages.ra.navigation, skip_nav: 'Пропустить' },
    },
    ...additionalMessages,
  }),
  'ru'
)
