import React, { useState, useEffect } from 'react'
import { useDataProvider, useListFilterContext } from 'react-admin'
import { Line } from 'react-chartjs-2'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { secondsToTime } from '../../../utils'

const useStyles = makeStyles({
  root: {
    margin: '1em 0',
  },
})

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabMapper = {
  0: 'days',
  1: 'weeks',
  2: 'months',
}

const PointsStatistics = ({
  path = 'daily_stats_graphs_by_day',
  convertValues = true,
}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [graphData, setGraphData] = useState(null)
  const dataProvider = useDataProvider()

  const {
    filterValues: { place, dateStart, dateEnd },
  } = useListFilterContext()

  useEffect(() => {
    if (dateStart && place) {
      dataProvider
        .getRequest(
          `${path}?dateStart=${+new Date(dateStart)}&dateEnd=${+new Date(
            dateEnd
          )}&place=${place}&type=${tabMapper[currentTab]}`
        )
        .then((resp) => {
          setGraphData(resp.data)
        })
        .catch((error) => console.error(error))
    }
  }, [place, dateStart, dateEnd, currentTab])

  const changeTabs = (_event, value) => {
    setCurrentTab(value)
  }

  const classes = useStyles()

  if (!place) {
    return null
  }

  return (
    <Card className={classes.root}>
      <Tabs
        value={currentTab}
        onChange={changeTabs}
        aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="День" {...a11yProps(0)} />
        <Tab label="Неделя" {...a11yProps(1)} />
        <Tab label="Месяц" {...a11yProps(2)} />
      </Tabs>
      <CardContent>
        {graphData && (
          <Line
            data={graphData}
            options={{
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    let label =
                      (data.datasets[tooltipItem.datasetIndex].labels &&
                        data.datasets[tooltipItem.datasetIndex].labels[
                          tooltipItem.index
                        ]) ||
                      data.datasets[tooltipItem.datasetIndex].label ||
                      data.labels[tooltipItem.index] ||
                      ''
                    if (label) {
                      label += ': '
                    }

                    const value =
                      data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ]

                    if (!convertValues) {
                      label +=
                        value +
                        (data.datasets[tooltipItem.datasetIndex].labelSuffix ||
                          '')
                      return label
                    }

                    label +=
                      secondsToTime(value) +
                      (data.datasets[tooltipItem.datasetIndex].labelSuffix ||
                        '')

                    return label
                  },
                },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback(value) {
                        if (!convertValues) {
                          return value
                        }

                        return secondsToTime(value)
                      },
                    },
                  },
                ],
              },
            }}
          />
        )}
      </CardContent>
    </Card>
  )
}

export default PointsStatistics
