import React from 'react'
import { Layout } from 'react-admin'
import { createStyles, withStyles } from '@material-ui/core/styles'
import TreeMenu from './TreeMenu'
import AdminAppBar from './AdminAppBar'

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 5,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  })

const MyLayout = (props) => {
  return <Layout {...props} menu={TreeMenu} appBar={AdminAppBar} />
}

export default withStyles(styles)(MyLayout)
