import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import FileDownload from 'js-file-download'
import API_SETTINGS from '../../../config'
import { getFromLocalStorage } from '../../../utils'

const sanitizeParams = (params) => {
  const {
    allowEmpty,
    alwaysOn,
    variant,
    record,
    resource,
    defaultValue,
    margin,
    helperText,
    ...props
  } = params

  return props
}

const ExportButton = ({
  fieldKey = 'inventory',
  path = 'inventory_ingredients',
  filename = 'Inventory_Ingredients',
  ...props
}) => {
  const downloadXls = () => {
    const params = sanitizeParams(props)

    if (!params[fieldKey]) {
      return
    }

    let stringifyParams = ''
    const paramKeys = Object.keys(params)

    paramKeys.forEach((paramKey, index) => {
      if (params[paramKey]) {
        stringifyParams += `${paramKey}=${params[paramKey]}${
          paramKeys[index + 1] ? '&' : ''
        }`
      }
    })

    axios({
      url: `${API_SETTINGS.host}/${path}?formatType=xlsx&${stringifyParams}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Authorization-Token': getFromLocalStorage('token'),
      },
    }).then((response) => {
      FileDownload(response.data, `${filename}_${params[fieldKey]}.xlsx`)
    })
  }

  return (
    <div
      style={{
        marginBottom: 12,
      }}
    >
      <Button color="primary" onClick={downloadXls}>
        <GetAppIcon color="primary" />
        &nbsp;&nbsp;Экспорт
      </Button>
    </div>
  )
}

export default ExportButton
