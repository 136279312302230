import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  NumberField,
  DateField,
  TextInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CustomDatagrid from '../shared/CustomDatagrid'
import { secondsToTime } from '../../utils'
import { isPermissionExist } from '../../routes'

const ViewButton = (props) => {
  const { record } = props

  if (!record || record.total) {
    return null
  }

  const { dateStart } = record

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/daily_stats_by_day',
        search: `filter=${JSON.stringify({
          place: record.place,
          dateStart: new Date(dateStart),
        })}`,
      }}
    >
      <VisibilityIcon />
    </Button>
  )
}

const useStyles = makeStyles({
  overdue: {
    backgroundColor: 'red',
    padding: 2,
  },
})

const DailiStatsListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput name="dateStart" alwaysOn label="Дата начала" />
      <DateInput name="dateEnd" alwaysOn label="Дата окончания" />
      <TextInput name="q" alwaysOn label="Поиск" />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <DailiStatsListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const NumberPercentField = (props) => {
  const { record, ...otherProps } = props

  const overdueOrdersPercent =
    ((record && record.overdueOrdersPercent) || 0) / 100

  return (
    <NumberField
      {...otherProps}
      record={{ ...record, overdueOrdersPercent }}
      options={{ style: 'percent', format: (value) => value / 100 }}
    />
  )
}

const TimeStampField = (props) => {
  const styles = useStyles()
  const { record, ...otherProps } = props
  const parsedLeadTimeSeconds = Math.round(record.leadTimeSeconds)
  const leadTimeSeconds = secondsToTime(parsedLeadTimeSeconds)
  const { preparationItemTime } = record

  return (
    <TextField
      {...otherProps}
      record={{ ...record, leadTimeSeconds }}
      className={
        parsedLeadTimeSeconds <= preparationItemTime
          ? undefined
          : styles.overdue
      }
    />
  )
}

const DateTotalField = (props) => {
  const { record, ...fieldProps } = props
  const { total = false } = record

  if (total) {
    return (
      <TextField
        source="dateStart"
        {...fieldProps}
        record={{ ...record, dateStart: 'Среднее' }}
      />
    )
  }

  return <DateField source="dateStart" {...props} />
}

const DailiStatsList = ({ permissions, ...props }) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={1000}
      sort={{ order: 'DESC', field: 'dateStart' }}
      filters={<DailiStatsListFilters />}
      empty={<Empty />}
      filterDefaultValues={filterDefaultValues}
    >
      <CustomDatagrid>
        <DateTotalField source="dateStart" />
        <NumberField
          source="payedTransactionsCount"
          additionalTooltipTitle="Все заказы, исключая возвраты, питание, списания"
        />
        <NumberField
          source="payedSumSum"
          additionalTooltipTitle="Выручка за день по Постеру"
        />
        <NumberField
          source="notOverdueOrders"
          additionalTooltipTitle="Зал, самовывоз, доставка с временем на кухне меньше 15 минут"
        />
        <NumberField
          source="overdueOrders"
          additionalTooltipTitle="Зал, самовывоз, доставка с временем на кухне больше 15 минут"
        />
        <NumberPercentField
          source="overdueOrdersPercent"
          additionalTooltipTitle="Процент просроченных от общего числа"
        />
        <TimeStampField
          source="leadTimeSeconds"
          additionalTooltipTitle="Медиана, с отсечением заказа меньше 5 минут - время выполнения типичного заказа"
        />
        {isPermissionExist(permissions, 'daily_stats_by_day') && <ViewButton />}
      </CustomDatagrid>
    </List>
  )
}

export default DailiStatsList
