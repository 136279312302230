import React, { useMemo } from 'react'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  BooleanInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import Pagination from '../../components/Pagination'
import { orderItemTypes } from '../../i18n/assignmentStatuses'

const SalesByTypesAndTimeFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <DateInput source="dateStart" alwaysOn label="Дата" />
      <BooleanInput source="hideKitchen" alwaysOn />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <SalesByTypesAndTimeFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const TypeField = (props) => {
  const { record } = props

  if (!props || !record || !record.type) {
    return null
  }

  return (
    <TextField
      {...props}
      record={{ ...record, type: orderItemTypes[record.type] }}
    />
  )
}

const TimeField = ({ source, ...props }) => {
  return (
    <div style={{ width: 90 }}>
      <TextField source={source} {...props} />
    </div>
  )
}

const SalesByTypesAndTime = (props) => {
  const filterDefaultValues = useMemo(() => {
    return { dateStart: new Date(), hideKitchen: true }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={200}
      sort={{ order: 'DESC', field: 'id' }}
      filters={<SalesByTypesAndTimeFilters />}
      filterDefaultValues={filterDefaultValues}
      empty={<Empty />}
      pagination={<Pagination />}
    >
      <>
        <CustomDatagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TypeField source="type" />

          <TimeField source="10" />
          <TimeField source="11" />
          <TimeField source="12" />
          <TimeField source="13" />
          <TimeField source="14" />
          <TimeField source="15" />
          <TimeField source="16" />
          <TimeField source="17" />
          <TimeField source="18" />
          <TimeField source="19" />
          <TimeField source="20" />
          <TimeField source="21" />
        </CustomDatagrid>
      </>
    </List>
  )
}

export default SalesByTypesAndTime
