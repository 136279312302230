import { fetchUtils } from 'react-admin'
import jsonServerProvider from './jsonServerProvider'
import API_SETTINGS from '../config'
import { getFromLocalStorage } from '../utils'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const city = getFromLocalStorage('city')
  const token = getFromLocalStorage('token')

  if (city) {
    options.headers.set('Statistics-City', city)
  }

  if (token) {
    options.headers.set('Authorization-Token', token)
  }

  return fetchUtils.fetchJson(url, options)
}

export default jsonServerProvider(`${API_SETTINGS.host}`, httpClient)
