const baseFields = {
  id: 'Id',
  createdAt: 'Создан',
  updatedAt: 'Обновлён',
  q: 'Поиск',
}

const orderItemsTypes = {
  dateStart: 'Дата',
  paidOrdersCount: 'Оплаченные заказы',
  pizzas: 'Пицц',
  halfPizzas: 'Пицц половинок',
  rolls: 'Роллов',
  pizzasAndRolls: 'Пицца и роллы',
  totalPizzas: 'Пицца',
  totalRolls: 'Роллы',
  staffPizzas: 'Пицц',
  staffHalfPizzas: 'Пицц половинок',
  staffRolls: 'Роллов',

  totalOrdersCount: 'Заказов всего',
  hallOrdersCount: 'Зал',
  pickupOrdersCount: 'Самовывоз',
  deliveryOrdersCount: 'Доставка',
  staffOrdersCount: 'Внутренний',
  writeoffOrdersCount: 'Списание',
  removedChecksCount: 'Удалено',
  unknownChecksCount: 'Неизвестные',
  orderReturnChecksCount: 'Возврат',
  orderPrintChecksCount: 'Печать',
  orderNotPrintChecksCount: 'Без чека',
  totalOnlineOrdersCount: 'Всего',
  approvedOnlineOrdersCount: 'Принято',
  rejectedOnlineOrdersCount: 'Отклонено',
  callCenterOnlineOrdersCount: 'Заказы из КЦ',

  place: 'Заведение',
  dateEnd: 'Дата окончания',
}

export default {
  resources: {
    city: {
      name: 'Город |||| Города',
      fields: {
        name: 'Название',
        ...baseFields,
      },
      menu: 'Города',
      empty: 'Нет созданных городов.',
      invite: 'Вы хотите создать новый город?',
    },
    inventory: {
      name: 'Инвентаризация |||| Инвентаризации',
      fields: {
        ...baseFields,
        inventoryId: 'Номер',
        dateInventory: 'Дата проведения',
        dateStart: 'Дата начала',
        dateEnd: 'Дата конца',
        sum: 'Отклонение',
        diffcurrencyPositive: 'Плюс',
        diffcurrencyNegative: 'Минус',
        revenue: 'Сумма продаж',
        negativePercentRevenue: 'минус от прод.%',
        positivePercentRevenue: 'плюс от прод.%',
        movementsSum: 'Сумма движений',
        place: 'Заведение',
      },
      empty: 'Нет созданных инвентаризаций.',
      menu: 'Инвентаризации',
    },
    inventory_ingredients: {
      name: 'Ингредиенты инвентаризации |||| Ингредиенты инвентаризации',
      fields: {
        ...baseFields,
        item: 'Название',
        startrest: 'Ост. на начало',
        income: 'Поступл.',
        charges: 'Расход',
        writeoff: 'Списано',
        writeoffcurrency: 'Спис.₴',
        estimatedrest: 'План. остаток',
        factrest: 'Факт. остаток',
        factRestSum: 'Остаток',
        difference: 'Разница',
        diffcurrency: 'Разница ₴',
        chargesPercent: 'от расхода%',
      },
      menu: 'Ингредиенты инвентаризации',
    },
    inventory_ingredients_info: {
      name: 'Ингредиент инвентаризации',
      fields: {
        ...baseFields,
        item: 'Название',
        startrest: 'Ост. на начало',
        income: 'Поступл.',
        charges: 'Расход',
        writeoff: 'Списано',
        writeoffcurrency: 'Спис.₴',
        estimatedrest: 'План. остаток',
        factrest: 'Факт. остаток',
        factRestSum: 'Остаток',
        difference: 'Разница',
        diffcurrency: 'Разница ₴',
        chargesPercent: 'от расхода%',
        'inventoryData.dateStart': 'Дата начала',
        'inventoryData.dateEnd': 'Дата окончания',
        perios: 'Период',
      },
      menu: 'Ингредиент инвентаризации',
    },
    storage: {
      name: 'Склад |||| Склады',
      fields: {
        ...baseFields,
        storageName: 'Название склада',
        storageAdress: 'Адрес склада',
        storageId: 'Номер склада.',
        place: 'Заведение',
        active: 'Активный склад',
      },
      empty: 'Нет созданных складов.',
      menu: 'Склады',
    },
    places: {
      name: 'Заведение |||| Заведения',
      fields: {
        ...baseFields,
        name: 'Название',
        token: 'Ключ доступа',
        addressLogin: "Адрес (логин) Poster'a",
        city: 'Город',
        serverAddress: 'Адрес сервера',
        cloudServerAddress: 'Адрес облачного сервера',
        isEnabled: 'Работает',
      },
      menu: 'Заведения',
      empty: 'Нет созданных заведений.',
      invite: 'Вы хотите создать новое заведение?',
    },
    order_items_type: {
      name: '% Заказы по товарам',
      fields: {
        ...baseFields,
        ...orderItemsTypes,
      },
      menu: '% Заказы по товарам',
      empty: 'Нет созданных заказов по товарам',
    },
    order_items_type_checks: {
      name: '% Заказы по типам',
      fields: {
        ...baseFields,
        ...orderItemsTypes,
      },
      menu: '% Заказы по типам',
      empty: 'Нет созданных заказов по типам',
    },

    // Разделы меню
    menu_settings: {
      name: 'Настройки',
      menu: 'Настройки',
    },
    statistics: {
      name: 'Статистика',
      menu: 'Статистика',
    },
    movements: {
      name: 'Отчет по движению',
      menu: 'Отчет по движению',
      fields: {
        ...baseFields,
        place: 'Заведение',
        storage: 'Склад',
        ingredientName: 'Имя ингредиента',
        costStart: 'Ср. себестоимость на начало',
        costEnd: 'Ср. себестоимость на конец',
        start: 'Ост. на начало',
        end: 'Ост. на конец',
        income: 'Поступления',
        writeOffs: 'Расход',
      },
    },

    daily_stats: {
      name: 'Время приготовления',
      menu: 'Время приготовления',
      fields: {
        ...baseFields,
        dateStart: 'Дата',
        payedSumSum: 'Оборот грн.',
        notOverdueOrders: 'Успели',
        overdueOrders: 'Не успели',
        overdueOrdersPercent: 'Не успели %',
        leadTimeSeconds: 'Среднее',
        transactionsCount: 'Заказов',
        payedTransactionsCount: 'Заказов',
        place: 'Заведение',
      },
    },

    daily_stats_by_day: {
      name: 'Время приготовления за день',
      menu: 'Время приготовления за день',
      fields: {
        ...baseFields,
        orderName: '№',
        assignment: 'Тип',
        payedSum: 'Сумма, грн.',
        dateStart: 'Старт на кухне',
        finishedAt: 'Заказ закрыт',
        totalTimeSeconds: 'Приготовлен',
        giveAwayTimeSeconds: 'Отдавали',
        leadTimeSeconds: 'Закрыт',
        isBar: 'Бар',
        comment: 'Коментарий',
        place: 'Заведение',
      },
    },

    sales: {
      name: 'Продажи',
      menu: 'Продажи',
    },

    sales_order_items: {
      name: 'Товары',
      menu: 'Товары',
      fields: {
        ...baseFields,
        place: 'Заведение',
        category: 'Категории',
        count: 'Количество',
        name: 'Наименование',
      },
    },

    categories: {
      name: 'Категории',
      menu: 'Категории',
      fields: {
        ...baseFields,
      },
    },

    sales_orders_by_type: {
      name: 'Тип заказов',
      menu: 'Тип заказов',
      fields: {
        ...baseFields,
        place: 'Заведение',
        count: 'Количество',
        assignment: 'Тип',
      },
    },

    sales_pizzas_by_diameters: {
      name: 'Диаметры',
      menu: 'Диаметры',
      fields: {
        ...baseFields,
        place: 'Заведение',
        count: 'Количество',
        size: 'Диаметр пиццы',
      },
    },

    sales_orders_by_time: {
      name: 'Время выполнения',
      menu: 'Время выполнения',
      fields: {
        ...baseFields,
        place: 'Заведение',
        orderName: 'Номер заказа',
        leadTimeSeconds: 'Время выполнения заказа',
      },
    },

    admin: {
      name: 'Пользователи |||| Пользователей',
      menu: 'Пользователи',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        name: 'Имя',
        email: 'e-mail',
        password: 'Пароль',
        passwordConfirmation: 'Повторите пароль',
        role: 'Роль',
        accessAllRoutes: 'Доступ к всем ролям',
      },
      empty: 'Нет созданных пользователей.',
      invite: 'Вы хотите создать нового пользователя?',
    },

    all_supplies: {
      name: 'Поставки',
      menu: 'Поставки',
    },

    supplies: {
      name: 'Поставка |||| Поставки',
      menu: 'Поставки',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        storageName: 'Название склада',
        supplierName: 'Имя поставщика',
        storageId: 'Id склада',
        supplyId: 'Id поставки',
        supplySum: 'Сумма, грн.',
        supplyComment: 'Комментарий поставки',
        date: 'Дата поставки',
        supplier: {
          supplierId: 'Id поставщика',
          supplierName: 'Имя поставщика',
          supplierPhone: 'Телефон поставщика',
          supplierAdress: 'Адрес поставщика',
          supplierComment: 'Комментарий поставщика',
          supplierCode: 'Юр. ИНН поставщика',
          supplierTin: 'ИНН поставщика',
        },
        supplyIngredients: {
          ingredientId: 'Id ингредиента',
          ingredientName: 'Имя ингредиента',
          ingredientUnit: 'Единицы',
          supplyIngredientNum: 'Количество',
          supplyIngredientSum: 'Общая стоимость',
        },
      },
      empty: 'Нет созданных поставок',
      invite: 'Вы хотите создать нового пользователя?',
    },

    suppliers: {
      name: 'Поставщик |||| Поставщики',
      menu: 'Поставщики',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        supplierId: 'Id поставщика',
        supplierName: 'Имя поставщика',
        supplierPhone: 'Телефон поставщика',
        supplierAdress: 'Адрес поставщика',
        supplierComment: 'Комментарий поставщика',
        supplierCode: 'Юр. ИНН поставщика',
        supplierTin: 'ИНН поставщика',
      },
      empty: 'Нет созданных поставщиков',
      invite: 'Вы хотите создать нового поставщика?',
    },

    supplies_orders: {
      name: 'Заказы',
      menu: 'Заказы',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        ingredientLeft: 'Остаток',
        ingredientName: 'Название',
        ingredientUnit: 'Единицы',
        ingredientsType: 'Тип ингредиента',
        supplyIngredientNum: 'Последний заказ, кол-во',
        needToSupply: 'Необходимо заказать',
      },
      empty: 'Нет созданных поставок',
      invite: 'Вы хотите создать нового пользователя?',
    },

    average_by_ingredients: {
      name: 'Средний расход',
      menu: 'Ср. расход ингр.',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        storageName: 'Название склада',
        ingredientId: 'Id ингредиента',
        ingredientName: 'Имя ингредиента',
        ingredientUnit: 'Единицы',
        supplyIngredientNum: 'Количество',
        charges: 'Расход (в день)',
        remainDays: 'Остаток в днях',
        ingredientLeft: 'Остаток на складе',
        ingredientsType: 'Тип ингредиента',
      },
    },

    sales_by_types_and_time: {
      name: 'Продажи по часам и типам продукции',
      menu: 'Продажи по часам и типам продукции',
      fields: {
        ...baseFields,
        place: 'Заведение',
        count: 'Количество',
        type: 'Тип',
        dateStart: 'Дата',
        name: 'Название',
        10: '10:00 - 11:00',
        11: '11:00 - 12:00',
        12: '12:00 - 13:00',
        13: '13:00 - 14:00',
        14: '14:00 - 15:00',
        15: '15:00 - 16:00',
        16: '16:00 - 17:00',
        17: '17:00 - 18:00',
        18: '18:00 - 19:00',
        19: '19:00 - 20:00',
        20: '20:00 - 21:00',
        21: '21:00 - 22:00',
        hideKitchen: 'Не отображать внутренние заказы (Питание)',
      },
    },

    poster_users: {
      name: 'Poster пользователь |||| Poster пользователи',
      fields: {
        ...baseFields,
        name: 'ФИО',
        posterName: 'ФИО Постер',
        userId: 'Poster Id',
        posPass: 'Poster PIN',
        phone: 'Телефон',
        roleId: 'Id роли',
        roleName: 'Роль',
        isShiftOpened: 'На смене?',
        place: 'Заведение',
        removed: 'Удален?',
      },
      menu: 'Poster пользователи',
      empty: 'Нет созданных Poster пользователей.',
    },

    poster_user_ratings: {
      name: 'Отзывы о Poster пользователях (Кассиры)',
      fields: {
        ...baseFields,
        place: 'Заведение',
        posterUser: 'Кассир',
        createdAtDate: 'Дата создания',
        goodRateStatus: 'Отзыв хороший?',
      },
      menu: 'Отзывы о Poster пользователях',
      empty: 'Нет созданных отзывов о Poster пользователях.',
    },
  },
}
