import React, { useMemo } from 'react'

import {
  List,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import { placeObject } from '../../utils'

const OrderItemsTypeListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>

      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <TextInput source="q" label="Поиск" alwaysOn />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <OrderItemsTypeListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const thArray = [
  { label: ' ', colSpan: 2 },
  { label: 'Чеки по типу заказов', colSpan: 7 },
  { label: 'Онлайн-заказы с сайта', colSpan: 4 },
  { label: 'Чек к заказу', colSpan: 3 },
]

const OrderItemsTypeChecksList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
      ...placeObject(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100}
      sort={{ order: 'DESC', field: 'dateStart' }}
      filters={<OrderItemsTypeListFilters />}
      empty={<Empty />}
      filterDefaultValues={filterDefaultValues}
    >
      <CustomDatagrid additionalHeadProp={thArray}>
        <DateField source="dateStart" />
        <TextField
          source="totalOrdersCount"
          additionalTooltipTitle="Все заказы, включая возвраты, питание, списания"
        />
        <TextField
          source="hallOrdersCount"
          additionalTooltipTitle="Оплаченные заказы в зале"
        />
        <TextField
          source="pickupOrdersCount"
          additionalTooltipTitle="Оплаченные заказы самовывоза"
        />
        <TextField
          source="deliveryOrdersCount"
          additionalTooltipTitle="Оплаченные заказы доставки"
        />
        <TextField
          source="staffOrdersCount"
          additionalTooltipTitle="Питание, внутренние"
        />
        <TextField
          source="writeoffOrdersCount"
          additionalTooltipTitle="Списания отдельными чеками"
        />
        <TextField
          source="removedChecksCount"
          additionalTooltipTitle="Удалено чеков"
        />
        <TextField
          source="unknownChecksCount"
          additionalTooltipTitle="Тип не назвачен,нет интернета или баг Постера"
        />

        <TextField
          source="totalOnlineOrdersCount"
          additionalTooltipTitle="Поступило заказов с сайта за день"
        />
        <TextField
          source="approvedOnlineOrdersCount"
          additionalTooltipTitle="Онлайн заказы, которые менеджер одобрил"
        />
        <TextField
          source="rejectedOnlineOrdersCount"
          additionalTooltipTitle="Онлайн заказы, которые менеджер отклонил"
        />
        <TextField
          source="callCenterOnlineOrdersCount"
          additionalTooltipTitle="Онлайн заказы из Колл Центра (в комментарии КЦ)"
        />

        <TextField
          source="orderReturnChecksCount"
          additionalTooltipTitle="Количество чеков, которым сделан возврат"
        />
        <TextField
          source="orderPrintChecksCount"
          additionalTooltipTitle="Оплачено и напечатан чек"
        />
        <TextField
          source="orderNotPrintChecksCount"
          additionalTooltipTitle="Оплачено, но чек не напечатан"
        />
      </CustomDatagrid>
    </List>
  )
}

export default OrderItemsTypeChecksList
