import React from 'react'

import { List, Datagrid, NumberField, Filter, TextInput } from 'react-admin'
import { dateToLocaleDateString } from '../../utils'
import IngredientInfo from './components/IngredientInfo'

const InventoryIngredientsListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

const DatesField = ({ record }) => {
  const { inventoryData } = record

  if (!inventoryData) {
    return null
  }

  const { dateStart, dateEnd } = inventoryData

  return (
    <span>
      {dateToLocaleDateString(dateStart)} - {dateToLocaleDateString(dateEnd)}
    </span>
  )
}

const InventoryIngredientsList = (props) => (
  <List
    {...props}
    filters={<InventoryIngredientsListFilter />}
    pagination={false}
    bulkActionButtons={false}
    perPage={1000}
    exporter={false}
    sort={{ order: 'DESC', field: 'dateEnd' }}
  >
    <>
      <IngredientInfo />
      <Datagrid>
        <DatesField source="inventoryData.dateEnd" />
        <NumberField source="income" />
        <NumberField source="charges" />
        <NumberField source="writeoff" />
        <NumberField source="writeoffcurrency" />
        <NumberField source="difference" />
        <NumberField source="diffcurrency" />
      </Datagrid>
    </>
  </List>
)

export default InventoryIngredientsList
