import React, { memo } from 'react'
import classnames from 'classnames'
import { TableCell } from '@material-ui/core'
import { FieldTitle, useResourceContext } from 'ra-core'

export const DatagridHeaderCell = (props) => {
  const { className, field } = props
  const resource = useResourceContext(props)

  return (
    <TableCell
      className={classnames(className)}
      variant="head"
      colSpan={field.colSpan}
      align="center"
    >
      <FieldTitle
        label={field.label}
        source={field.source}
        resource={resource}
      />
    </TableCell>
  )
}

export default memo(DatagridHeaderCell)
