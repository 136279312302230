import React, { useState, useEffect } from 'react'
import { useListContext, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles({
  header: { margin: '1em' },
})

const IngredientInfo = () => {
  const listContext = useListContext()
  const classes = useStyles()

  const {
    filterValues: { itemId, inventory },
  } = listContext || { filterValues: {} }

  const dataProvider = useDataProvider()
  const [ingredientInfo, setIngredientInfo] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    dataProvider
      .getRequest(`inventory_ingredients_info/${itemId}?inventory=${inventory}`)
      .then((resp) => setIngredientInfo(resp.data))
      .catch(() => setError('Ингредиент с таким ID не найден'))
  }, [])

  if (error) {
    return <h2>{error}</h2>
  }

  if (ingredientInfo) {
    const { item } = ingredientInfo

    return (
      <div className={classes.header}>
        <h2>История инвентаризации по ингредиенту</h2>
        <Divider />
        <h2>{item}</h2>
      </div>
    )
  }

  return null
}

export default IngredientInfo
