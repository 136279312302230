import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  Pagination,
  DateField,
  ArrayField,
  TabbedShowLayout,
  Tab,
} from 'react-admin'

const SupplyPagination = (props) => (
  <Pagination rowsPerPageOptions={[250, 500, 1000]} {...props} />
)

const SuppliersListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const ExpandComponent = (props) => {
  const UnitField = (unitProps) => {
    const {
      record: { ingredientUnit },
      record,
    } = unitProps

    const translatedTypes = (type) => {
      let newType = ''
      switch (type) {
        case 'kg':
          newType = 'кг'
          break
        case 'p':
          newType = 'шт'
          break
        default:
          newType = 'л'
      }
      return newType
    }

    const changedRecord = {
      ...record,
      ingredientUnit: translatedTypes(ingredientUnit),
    }

    return (
      <TextField
        source="ingredientUnit"
        label="Единицы"
        {...unitProps}
        record={changedRecord}
      />
    )
  }

  return (
    <TabbedShowLayout {...props}>
      <Tab label="Поставщик">
        <TextField source="supplier.supplierId" />
        <TextField source="supplier.supplierName" />
        <TextField source="supplier.supplierPhone" />
        <TextField source="supplier.supplierAdress" />
        <TextField source="supplier.supplierComment" />
        <TextField source="supplier.supplierCode" />
        <TextField source="supplier.supplierTin" />
      </Tab>
      <Tab label="Ингредиенты поставки">
        <ArrayField source="supplyIngredients" label={null}>
          <Datagrid>
            <TextField source="ingredientId" label="Id ингредиента" />
            <TextField source="ingredientName" label="Имя ингредиента" />
            <TextField source="supplyIngredientNum" label="Количество" />
            <UnitField source="ingredientUnit" label="Единицы" />
            <TextField
              source="supplyIngredientSum"
              label="Общая стоимость грн."
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  )
}

const SuppliersList = (props) => (
  <List
    {...props}
    filters={<SuppliersListFilters />}
    exporter={false}
    empty={false}
    bulkActionButtons={false}
    pagination={<SupplyPagination />}
    perPage={250}
  >
    <Datagrid expand={<ExpandComponent />}>
      <TextField source="supplyId" />
      <TextField source="storageName" />
      <TextField source="supplierName" />
      <TextField source="supplySum" />
      <TextField source="supplyComment" />
      <DateField source="date" showTime />
    </Datagrid>
  </List>
)

export default SuppliersList
