import React from 'react'

import { List, Datagrid, TextField, EditButton, EmailField } from 'react-admin'
import { adminRoles } from '../../i18n/adminRoles'

const RoleField = (props) => {
  const { record } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        role: adminRoles[record.role || 'admin'],
      }}
    />
  )
}

const AdminList = (props) => {
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField source="name" />
        <EmailField source="email" />
        <RoleField source="role" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default AdminList
