import React from 'react'

import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'

const PlaceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="token" required />
      <TextInput source="addressLogin" required />
      <TextInput source="serverAddress" />
      <TextInput source="cloudServerAddress" />
      <ReferenceInput source="city" reference="city">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="isEnabled" />
    </SimpleForm>
  </Edit>
)

export default PlaceEdit
