import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  Pagination,
} from 'react-admin'

const SupplyPagination = (props) => (
  <Pagination rowsPerPageOptions={[250, 500, 1000]} {...props} />
)

const SuppliesListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const SuppliesList = (props) => (
  <List
    {...props}
    filters={<SuppliesListFilters />}
    exporter={false}
    empty={false}
    bulkActionButtons={false}
    pagination={<SupplyPagination />}
    perPage={250}
  >
    <Datagrid>
      <TextField source="supplierId" />
      <TextField source="supplierName" />
      <TextField source="supplierPhone" />
      <TextField source="supplierAdress" />
      <TextField source="supplierComment" />
      <TextField source="supplierCode" />
      <TextField source="supplierTin" />
    </Datagrid>
  </List>
)

export default SuppliesList
