import React, { useState, useEffect, useCallback } from 'react'
import { useDataProvider, usePermissions } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import {
  setToLocalStorage,
  removeFromLocalStorage,
  getFromLocalStorage,
} from '../../utils'

const ID = 'city'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: 'white',

    '& label, & svg, & div': {
      color: 'white',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'white',
    },
  },
}))

const CitySelect = () => {
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const [cities, setCities] = useState([])
  const [cityValue, setValue] = useState('')
  const [error, setError] = useState(null)
  const { permissions } = usePermissions()

  const isAcminUser = permissions && permissions.role === 'admin'

  useEffect(() => {
    if (isAcminUser) {
      dataProvider
        .getList('city', {})
        .then((resp) => setCities(resp.data))
        .catch(() => setError('Сервис с городами не доступен'))
    }
  }, [isAcminUser])

  useEffect(() => {
    if (isAcminUser) {
      const storageCity = getFromLocalStorage(ID)
      if (storageCity) {
        setValue(storageCity)
      }
    }
  }, [isAcminUser])

  const changeSelect = useCallback((event) => {
    const {
      target: { value },
    } = event

    if (value) {
      setToLocalStorage(ID, value)
    } else {
      removeFromLocalStorage(ID)
    }
    setValue(value)
    window.location.reload()
  }, [])

  if (!isAcminUser) {
    return null
  }

  if (error) {
    return <h5>{error}</h5>
  }

  const mappedCities = cities.map((city) => {
    return (
      <MenuItem value={city.id} key={city.id}>
        {city.name}
      </MenuItem>
    )
  })

  if (cities) {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="citySelect">Город</InputLabel>
        <Select
          labelId="citySelect"
          className={classes.select}
          onChange={changeSelect}
          displayEmpty
          value={cityValue}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {mappedCities}
        </Select>
      </FormControl>
    )
  }

  return null
}

export default CitySelect
