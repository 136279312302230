import React from 'react'

import { Edit, SimpleForm, TextInput } from 'react-admin'

const CityEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" required />
    </SimpleForm>
  </Edit>
)

export default CityEdit
