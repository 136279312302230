import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  Pagination,
  NumberField,
} from 'react-admin'

const SupplyPagination = (props) => (
  <Pagination rowsPerPageOptions={[250, 500, 1000]} {...props} />
)

const AverageByIngredientsListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const UnitField = (unitProps) => {
  const {
    record: { ingredientUnit },
    record,
  } = unitProps

  const translatedTypes = (type) => {
    let newType = ''
    switch (type) {
      case 'kg':
        newType = 'кг'
        break
      case 'p':
        newType = 'шт'
        break
      default:
        newType = 'л'
    }
    return newType
  }

  const changedRecord = {
    ...record,
    ingredientUnit: translatedTypes(ingredientUnit),
  }

  return (
    <TextField
      source="ingredientUnit"
      label="Единицы"
      {...unitProps}
      record={changedRecord}
    />
  )
}

const IngredientTypeField = (props) => {
  const {
    record: { ingredientsType },
    record,
  } = props

  const changedRecord = {
    ...record,
    ingredientsType: ingredientsType === 2 ? 'продукт' : 'ингредиент',
  }

  return (
    <TextField source="ingredientsType" {...props} record={changedRecord} />
  )
}

const AverageByIngredientsList = (props) => (
  <List
    {...props}
    filters={<AverageByIngredientsListFilters />}
    exporter={false}
    empty={false}
    bulkActionButtons={false}
    pagination={<SupplyPagination />}
    perPage={250}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="ingredientName" />
      <IngredientTypeField source="ingredientsType" />
      <TextField source="ingredientLeft" />
      <UnitField source="ingredientUnit" />
      <NumberField source="charges" />
      <TextField source="remainDays" />
    </Datagrid>
  </List>
)

export default AverageByIngredientsList
