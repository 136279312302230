import React, { useMemo } from 'react'
import {
  List,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'

const OrderItemsTypeListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>

      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <TextInput source="q" label="Поиск" alwaysOn />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <OrderItemsTypeListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const thArray = [
  { label: ' ', colSpan: 2 },
  { label: 'Кол-во заказов, в которых есть', colSpan: 3 },
  { label: 'За день продано', colSpan: 3 },
  { label: 'Без оплаты', colSpan: 3 },
]

const OrderItemsTypeList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100}
      sort={{ order: 'DESC', field: 'dateStart' }}
      filters={<OrderItemsTypeListFilters />}
      empty={<Empty />}
      filterDefaultValues={filterDefaultValues}
    >
      <CustomDatagrid additionalHeadProp={thArray}>
        <DateField source="dateStart" />
        <TextField
          source="paidOrdersCount"
          additionalTooltipTitle="Все заказы, исключая возвраты, питание, списания"
        />
        <TextField
          source="totalPizzas"
          additionalTooltipTitle="Заказы в которых есть пицца, но нет роллов"
        />
        <TextField
          source="totalRolls"
          additionalTooltipTitle="Заказы в которых есть роллы, но нет пиццы"
        />
        <TextField
          source="pizzasAndRolls"
          additionalTooltipTitle="Заказы в которых есть и пицца и роллы"
        />
        <TextField
          source="pizzas"
          additionalTooltipTitle="Продано пицц за день"
        />
        <TextField
          source="halfPizzas"
          additionalTooltipTitle="Продано пицц-половинок за день"
        />
        <TextField
          source="rolls"
          additionalTooltipTitle="Продано суши за день"
        />
        <TextField
          source="staffPizzas"
          additionalTooltipTitle="Пробито за счет заведения, питание, списание"
        />
        <TextField
          source="staffHalfPizzas"
          additionalTooltipTitle="Пробито за счет заведения, питание, списание"
        />
        <TextField
          source="staffRolls"
          additionalTooltipTitle="Пробито за счет заведения, питание, списание"
        />
      </CustomDatagrid>
    </List>
  )
}

export default OrderItemsTypeList
