import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

const PosterUsersListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const PosterUsersList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<PosterUsersListFilters />}
      empty={false}
      sort={{ order: 'DESC', field: 'isShiftOpened' }}
      perPage={50}
    >
      <Datagrid>
        <TextField source="userId" />
        <TextField source="posterName" />
        <TextField source="roleName" />
        <BooleanField source="isShiftOpened" />
        <BooleanField source="removed" />
      </Datagrid>
    </List>
  )
}

export default PosterUsersList
