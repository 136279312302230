export const dateToLocaleString = (string) => {
  return new Date(string).toLocaleString()
}

export const dateToLocaleDateString = (string) => {
  if (!string) {
    return null
  }

  return new Date(string).toLocaleDateString()
}

export const secondsToTime = (secs) => {
  const hours = Math.floor(secs / (60 * 60))

  const divisorForMinutes = secs % (60 * 60)
  const minutes = Math.floor(divisorForMinutes / 60)

  const divisorForSeconds = divisorForMinutes % 60
  const seconds = Math.ceil(divisorForSeconds)

  const obj = {
    'ч.': hours,
    'м.': minutes,
    'с.': seconds,
  }

  const value = Object.keys(obj)
    .reduce((prev, currentValue) => {
      if (obj[currentValue]) {
        return `${prev} ${obj[currentValue]}${currentValue}`
      }

      return prev
    }, '')
    .trim()

  if (value) {
    return value
  }

  return '0c'
}
