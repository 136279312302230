import React, { useMemo } from 'react'

import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  NumberField,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'

const MovementsListFilters = (props) => {
  const { filterValues = {}, setFilters } = props

  const { place } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput
        source="place"
        reference="places"
        alwaysOn
        onChange={(event) => {
          const {
            target: { value },
          } = event

          if (!value) {
            setFilters({ ...filterValues, storage: undefined, place: value })
          } else {
            setFilters({ ...filterValues, place: value, storage: undefined })
          }
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="storage"
        reference="storage"
        alwaysOn
        filter={{ place, active: true }}
      >
        <SelectInput optionText="storageName" />
      </ReferenceInput>
      <DateInput name="dateStart" alwaysOn label="Дата начала" />
      <DateInput name="dateEnd" alwaysOn label="Дата окончания" />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <MovementsListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const MovementsList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100}
      sort={{ order: 'DESC', field: 'dateStart' }}
      filters={<MovementsListFilters />}
      empty={<Empty />}
      filterDefaultValues={filterDefaultValues}
    >
      <CustomDatagrid>
        <TextField source="ingredientName" sortable={false} />
        <NumberField source="writeOffs" sortable={false} />
        <NumberField source="income" sortable={false} />
        <NumberField source="start" sortable={false} />
        <NumberField source="end" sortable={false} />
        <NumberField source="costStart" sortable={false} />
        <NumberField source="costEnd" sortable={false} />
      </CustomDatagrid>
    </List>
  )
}

export default MovementsList
