import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  List,
  DateField,
  Filter,
  NumberField,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Pagination from '../../components/Pagination'
import CustomDatagrid from '../shared/CustomDatagrid'
import { placeObject } from '../../utils'
import { isPermissionExist } from '../../routes'

const ViewButton = (props) => {
  const { record } = props

  if (!record) {
    return null
  }

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/inventory_ingredients',
        search: `filter=${JSON.stringify({ inventory: record.id })}`,
      }}
    >
      <VisibilityIcon />
    </Button>
  )
}

const InventoryListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <InventoryListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const InventoryList = ({ permissions, ...props }) => {
  const filterDefaultValues = useMemo(() => placeObject(), [])

  return (
    <List
      {...props}
      sort={{ order: 'DESC', field: 'inventoryId' }}
      filters={<InventoryListFilters />}
      perPage={200}
      pagination={<Pagination />}
      bulkActionButtons={false}
      exporter={false}
      empty={<Empty />}
      filterDefaultValues={filterDefaultValues}
    >
      <CustomDatagrid>
        <NumberField source="inventoryId" />
        <DateField source="dateInventory" showTime />
        <DateField source="dateStart" showTime />
        <DateField source="dateEnd" showTime />
        <NumberField source="sum" />
        <NumberField source="diffcurrencyPositive" />
        <NumberField source="diffcurrencyNegative" />
        <NumberField source="movementsSum" />
        <NumberField source="revenue" />
        <NumberField source="negativePercentRevenue" />
        <NumberField source="positivePercentRevenue" />
        {isPermissionExist(permissions, 'inventory_ingredients') && (
          <ViewButton />
        )}
      </CustomDatagrid>
    </List>
  )
}

export default InventoryList
