import React, { useState, useEffect } from 'react'
import { useListContext, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import { dateToLocaleString } from '../../../../utils'

const useStyles = makeStyles({
  header: { margin: '1em' },
})

const InventoryInfo = () => {
  const listContext = useListContext()
  const classes = useStyles()

  const {
    filterValues: { inventory },
  } = listContext || { filterValues: {} }

  const dataProvider = useDataProvider()
  const [inventoryInfo, setInventoryInfo] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    dataProvider
      .getOne('inventory', { id: inventory })
      .then((resp) => setInventoryInfo(resp.data))
      .catch(() => setError('Инвентаризация с таким ID не найдена'))
  }, [])

  if (error) {
    return <h2>{error}</h2>
  }

  if (inventoryInfo) {
    const { inventoryId, dateStart, dateEnd } = inventoryInfo

    return (
      <div className={classes.header}>
        <h2>Отчет по инвентаризации № {inventoryId}</h2>
        <Divider />
        <h2>
          Товары и ингредиенты в инвентаризации {dateToLocaleString(dateStart)}{' '}
          - {dateToLocaleString(dateEnd)}
        </h2>
      </div>
    )
  }

  return null
}

export default InventoryInfo
