import React, { useMemo } from 'react'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import ExportButton from '../shared/ExportButton'

const byGroupsChoices = [
  { id: 'true', label: 'По названию' },
  { id: 'false', label: 'Все' },
]

const SalesOrderItemsListFilters = (props) => {
  const { filterValues = {}, setFilters } = props
  const { byGroups, dateStart, dateEnd, q } = filterValues

  const { place } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput
        source="place"
        reference="places"
        alwaysOn
        onChange={(event) => {
          const {
            target: { value },
          } = event

          if (!value) {
            setFilters({ ...filterValues, category: undefined, place: value })
          } else {
            setFilters({ ...filterValues, place: value })
          }
        }}
      >
        <SelectInput source="name" />
      </ReferenceInput>

      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <ExportButton
        alwaysOn
        fieldKey="place"
        path="sales_order_items"
        filename="Sales_order_items"
        {...{
          place,
          byGroups,
          dateStart: +new Date(dateStart),
          dateEnd: +new Date(dateEnd),
          q,
        }}
      />
      {place && (
        <ReferenceArrayInput
          source="category"
          reference="categories"
          alwaysOn
          filter={{ place }}
          style={{ minWidth: 200 }}
          allowEmpty={false}
        >
          <AutocompleteArrayInput optionText="categoryName" />
        </ReferenceArrayInput>
      )}
      <TextInput source="q" label="Поиск" />
      <SelectInput
        source="byGroups"
        label="Тип"
        optionText="label"
        allowEmpty={false}
        choices={byGroupsChoices}
      />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <SalesOrderItemsListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const FieldName = ({ source, record }) => {
  const { type, bort, size } = record

  let name = ''

  switch (type) {
    case 'pizza':
      name = `${record[source]} ${size} ${bort ? 'с бортиком' : 'без бортика'}`
      break
    default:
      name = record[source]
  }

  return <span>{name}</span>
}

const SalesOrderItemsList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
      byGroups: byGroupsChoices[1].id,
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100}
      sort={{ order: 'DESC', field: 'name' }}
      filters={<SalesOrderItemsListFilters />}
      filterDefaultValues={filterDefaultValues}
      empty={<Empty />}
    >
      <CustomDatagrid>
        <FieldName source="name" />
        <TextField source="count" />
      </CustomDatagrid>
    </List>
  )
}

export default SalesOrderItemsList
