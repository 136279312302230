import React, { useMemo } from 'react'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import PointsStatistics from '../shared/PointsStatistics'
import ExportButton from '../shared/ExportButton'

const SalesPizzasByDiametersListFilters = (props) => {
  const { filterValues = {} } = props
  const { place, dateStart, dateEnd } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>

      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <ExportButton
        alwaysOn
        fieldKey="place"
        path="sales_orders_by_type"
        filename="Sales_orders_by_type"
        {...{
          place,
          dateStart: +new Date(dateStart),
          dateEnd: +new Date(dateEnd),
          sort: 'size',
        }}
      />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <SalesPizzasByDiametersListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const SizeFieldComponent = (props) => {
  const {
    record: { size, bort, isHalfOrderItem },
    record,
  } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        size: `${isHalfOrderItem ? '1/2 ' : ''} Пиццы ${size} ${
          bort ? 'с бортиком' : 'без бортика'
        }`,
      }}
    />
  )
}
const SalesPizzasByDiametersList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100}
      sort={{ order: 'ASC', field: 'size' }}
      filters={<SalesPizzasByDiametersListFilters />}
      filterDefaultValues={filterDefaultValues}
      empty={<Empty />}
    >
      <>
        <PointsStatistics
          path="sales_pizzas_graphs_by_diameters"
          convertValues={false}
        />
        <CustomDatagrid>
          <SizeFieldComponent source="size" />
          <TextField source="count" />
        </CustomDatagrid>
      </>
    </List>
  )
}

export default SalesPizzasByDiametersList
