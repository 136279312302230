import * as React from 'react'
import { memo, useState } from 'react'
import classnames from 'classnames'
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { FieldTitle, useTranslate, useResourceContext } from 'ra-core'

// remove the sort icons when not active
const useStyles = makeStyles(
  {
    icon: {
      display: 'none',
    },
    active: {
      '& $icon': {
        display: 'inline',
      },
    },
  },
  { name: 'RaDatagridHeaderCell' }
)

export const DatagridHeaderCell = (props) => {
  const {
    className,
    classes: classesOverride,
    field,
    currentSort,
    updateSort,
    isSorting,
    ...rest
  } = props
  const resource = useResourceContext(props)
  const classes = useStyles(props)
  const translate = useTranslate()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false)

  return (
    <TableCell
      className={classnames(className, field.props.headerClassName)}
      align={field.props.textAlign}
      variant="head"
      {...rest}
    >
      {field.props.sortable !== false &&
      (field.props.sortBy || field.props.source) ? (
        <Tooltip
          title={translate('ra.action.sort')}
          placement={
            field.props.textAlign === 'right' ? 'bottom-end' : 'bottom-start'
          }
          enterDelay={300}
          onOpen={() => setTooltipOpen(true)}
          onClose={() => setTooltipOpen(false)}
          open={!infoTooltipOpen && tooltipOpen}
        >
          <TableSortLabel
            active={
              currentSort.field === (field.props.sortBy || field.props.source)
            }
            direction={currentSort.order === 'ASC' ? 'asc' : 'desc'}
            data-sort={field.props.sortBy || field.props.source} // @deprecated. Use data-field instead.
            data-field={field.props.sortBy || field.props.source}
            data-order={field.props.sortByOrder || 'ASC'}
            onClick={updateSort}
            classes={classes}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FieldTitle
                label={field.props.label}
                source={field.props.source}
                resource={resource}
              />
              {field.props.additionalTooltipTitle && (
                <>
                  &nbsp;
                  <Tooltip
                    title={field.props.additionalTooltipTitle || ''}
                    placement="bottom-start"
                    onOpen={() => setInfoTooltipOpen(true)}
                    onClose={() => setInfoTooltipOpen(false)}
                    open={infoTooltipOpen}
                  >
                    <InfoOutlinedIcon
                      color="action"
                      style={{ width: '0.75em', height: '0.75em' }}
                    />
                  </Tooltip>
                </>
              )}
            </div>
          </TableSortLabel>
        </Tooltip>
      ) : (
        <FieldTitle
          label={field.props.label}
          source={field.props.source}
          resource={resource}
        />
      )}
    </TableCell>
  )
}

export default memo(
  DatagridHeaderCell,
  (props, nextProps) =>
    props.updateSort === nextProps.updateSort &&
    props.currentSort.field === nextProps.currentSort.field &&
    props.currentSort.order === nextProps.currentSort.order &&
    props.isSorting === nextProps.isSorting &&
    props.resource === nextProps.resource
)
