import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from 'react-admin'

const PlaceCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="token" validate={required()} />
      <TextInput source="addressLogin" validate={required()} />
      <TextInput source="serverAddress" />
      <TextInput source="cloudServerAddress" />
      <ReferenceInput source="city" reference="city" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="isEnabled" />
    </SimpleForm>
  </Create>
)

export default PlaceCreate
