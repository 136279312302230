import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  TextField,
  Filter,
  NumberField,
  DateField,
  TextInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import { secondsToTime } from '../../utils'
import { assignmentStatuses } from '../../i18n/assignmentStatuses'
import Statistics from './components/Statistics'

const useStyles = makeStyles({
  overdue: {
    backgroundColor: 'red',
    padding: 2,
  },
})

const TimeStampField = ({
  preparationItemTime,
  showError = true,
  ...props
}) => {
  const styles = useStyles()
  const { record, ...otherProps } = props
  const parsedLeadTimeSeconds = Math.round(record.totalTimeSeconds)
  const totalTimeSeconds = secondsToTime(parsedLeadTimeSeconds)

  const className = showError
    ? parsedLeadTimeSeconds <= preparationItemTime
      ? undefined
      : styles.overdue
    : undefined

  return (
    <TextField
      {...otherProps}
      record={{ ...record, totalTimeSeconds }}
      className={className}
    />
  )
}

const Filters = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="q" label="Поиск" alwaysOn />
    </Filter>
  )
}

const AssignmentFieldComponent = (props) => {
  const { record } = props
  return (
    <TextField
      source="orderName"
      {...props}
      record={{
        ...record,
        assignment: assignmentStatuses[record.assignment || 'Hall'],
      }}
    />
  )
}

const BarField = (props) => {
  const { record } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        isBar: record && record.isBar ? 'Да' : 'Нет',
      }}
    />
  )
}

const CommentField = (props) => {
  const { record = {} } = props

  const { clientName = '', comment = '' } = record

  return (
    <TextField
      {...props}
      record={{
        ...record,
        comment: `${clientName ? `Имя: ${clientName} ` : ''}${comment}`,
      }}
    />
  )
}

const GiveAwayField = (props) => {
  const { record = {} } = props

  const { giveAwayTimeSeconds } = record

  return (
    <TextField
      {...props}
      record={{
        ...record,
        giveAwayTimeSeconds: secondsToTime(giveAwayTimeSeconds),
      }}
    />
  )
}

const ClosedAtField = (props) => {
  const { record = {} } = props

  const { leadTimeSeconds } = record

  return (
    <TextField
      {...props}
      record={{
        ...record,
        leadTimeSeconds: secondsToTime(leadTimeSeconds),
      }}
    />
  )
}

const ExpandComponent = (props) => {
  const {
    record: { items },
  } = props

  const mappedItems = items.map((item) => {
    const { name, type, size, isHalfOrderItem } = item

    if (type === 'pizza') {
      return (
        <div>
          {isHalfOrderItem ? `1/2 ` : ''}Пицца {name} {size}
        </div>
      )
    }
    return <div>{name}</div>
  })

  return <div>{mappedItems}</div>
}

const DailiStatsList = (props) => {
  const [preparationItemTime] = useState(900)

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={1000}
      sort={{ order: 'DESC', field: 'orderName' }}
      filters={<Filters />}
    >
      <>
        <Statistics />
        <CustomDatagrid expand={<ExpandComponent />}>
          <TextField source="orderName" additionalTooltipTitle="Номер заказа" />
          <AssignmentFieldComponent
            source="assignment"
            additionalTooltipTitle="Тип заказа - Зал, Доставка, Самовывоз"
          />
          <NumberField source="payedSum" additionalTooltipTitle="Сумма чека" />
          <DateField
            source="dateStart"
            showTime
            additionalTooltipTitle="Время появления заказа на кухне"
          />
          <DateField
            source="finishedAt"
            showTime
            additionalTooltipTitle='Кассир отметил "Готово"'
          />
          <TimeStampField
            source="totalTimeSeconds"
            additionalTooltipTitle="Время по кухне мин:сек"
            preparationItemTime={preparationItemTime}
            showError
          />
          <GiveAwayField
            source="giveAwayTimeSeconds"
            additionalTooltipTitle="Время на полке, до нажатия Готово, мин:сек"
          />
          <ClosedAtField
            source="leadTimeSeconds"
            additionalTooltipTitle="Общее время от начала до выдачи в зале, мин:сек"
          />
          <BarField
            source="isBar"
            additionalTooltipTitle="Только напитки, мороженое - быстрая выдача"
          />
          <CommentField
            source="comment"
            additionalTooltipTitle="Комментарий к заказу: Имя гостя, примечание"
          />
        </CustomDatagrid>
      </>
    </List>
  )
}

export default DailiStatsList
