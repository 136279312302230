import React, { useMemo } from 'react'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  FunctionField,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import Pagination from '../../components/Pagination'
import PointsStatistics from '../shared/PointsStatistics'
import ExportButton from '../shared/ExportButton'
import { secondsToTime } from '../../utils'

const SalesOrdersByTimeListFilters = (props) => {
  const { filterValues = {} } = props
  const { place, dateStart, dateEnd, q } = filterValues
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <ExportButton
        alwaysOn
        fieldKey="place"
        path="sales_orders_by_time"
        filename="Sales_orders_by_time"
        {...{
          place,
          dateStart: +new Date(dateStart),
          dateEnd: +new Date(dateEnd),
          q,
        }}
      />
      <TextInput source="q" label="Поиск" alwaysOn />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <SalesOrdersByTimeListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const SalesOrdersByTimeList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={100}
      sort={{ order: 'DESC', field: 'orderName' }}
      filters={<SalesOrdersByTimeListFilters />}
      filterDefaultValues={filterDefaultValues}
      empty={<Empty />}
      pagination={<Pagination />}
    >
      <>
        <PointsStatistics path="sales_orders_graphs_by_time" />
        <CustomDatagrid>
          <TextField source="orderName" />
          <FunctionField
            source="leadTimeSeconds"
            render={(record) => secondsToTime(record.leadTimeSeconds || 0)}
          />
        </CustomDatagrid>
      </>
    </List>
  )
}

export default SalesOrdersByTimeList
