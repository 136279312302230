import * as React from 'react'
import { isValidElement, Children, cloneElement, useCallback } from 'react'
import { sanitizeListRestProps, useListContext, useVersion } from 'ra-core'
import {
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import classnames from 'classnames'
import {
  // DatagridHeaderCell,
  DatagridLoading,
  DatagridBody,
  useDatagridStyles,
  PureDatagridBody,
} from 'react-admin'
import NoData from '../NoData'
import CustomDatagridHeaderCell from '../CustomDatagridHeaderCell'
import { DatagridHeaderCell } from '../DatagridHeaderCell'

/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 *
 *
 * @example Usage outside of a <List> or a <ReferenceManyField>.
 *
 * const currentSort = { field: 'published_at', order: 'DESC' };
 *
 * export const MyCustomList = (props) => {
 *     const { ids, data, total, loaded } = useGetList(
 *         'posts',
 *         { page: 1, perPage: 10 },
 *         currentSort
 *     );
 *
 *     return (
 *         <Datagrid
 *             basePath=""
 *             currentSort={currentSort}
 *             data={data}
 *             ids={ids}
 *             selectedIds={[]}
 *             loaded={loaded}
 *             total={total}
 *             setSort={() => {
 *                 console.log('set sort');
 *             }}
 *             onSelect={() => {
 *                 console.log('on select');
 *             }}
 *             onToggleItem={() => {
 *                 console.log('on toggle item');
 *             }}
 *         >
 *             <TextField source="id" />
 *             <TextField source="title" />
 *         </Datagrid>
 *     );
 * }
 */
const Datagrid = React.forwardRef((props, ref) => {
  const classes = useDatagridStyles(props)
  const {
    optimized = false,
    body = optimized ? <PureDatagridBody /> : <DatagridBody />,
    children,
    classes: classesOverride,
    className,
    expand,
    hasBulkActions = false,
    hover,
    isRowSelectable,
    resource,
    rowClick,
    rowStyle,
    size = 'small',
    additionalHeadProp,
    ...rest
  } = props

  const {
    basePath,
    currentSort,
    data,
    ids,
    loaded,
    onSelect,
    onToggleItem,
    selectedIds,
    setSort,
    total,
  } = useListContext(props)
  const version = useVersion()

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation()
      const newField = event.currentTarget.dataset.field
      const newOrder =
        currentSort.field === newField
          ? currentSort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order

      setSort(newField, newOrder)
    },
    [currentSort.field, currentSort.order, setSort]
  )

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        const all = ids.concat(selectedIds.filter((id) => !ids.includes(id)))
        onSelect(
          isRowSelectable ? all.filter((id) => isRowSelectable(data[id])) : all
        )
      } else {
        onSelect([])
      }
    },
    [data, ids, onSelect, isRowSelectable, selectedIds]
  )
  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={Children.count(children)}
        size={size}
      />
    )
  }
  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  if (loaded && (ids.length === 0 || total === 0)) {
    return <NoData />
  }
  const all = isRowSelectable
    ? ids.filter((id) => isRowSelectable(data[id]))
    : ids
  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */

  const additionalStyles = additionalHeadProp ? { top: 30 } : {}

  const clonedChidlren = Children.map(children, (child) => {
    if (!child) {
      return null
    }

    const {
      props: { additionalTooltipTitle, ...otherProps },
    } = child

    return { ...child, props: otherProps }
  })

  return (
    <Table
      ref={ref}
      className={classnames(classes.table, className)}
      size={size}
      {...sanitizeListRestProps(rest)}
    >
      <TableHead className={classes.thead}>
        {additionalHeadProp && (
          <TableRow className={classnames(classes.row, classes.headerRow)}>
            {additionalHeadProp.map((field, index) => {
              return (
                <CustomDatagridHeaderCell
                  className={classes.headerCell}
                  field={field}
                  key={`${String(index)}_stats`}
                  resource={resource}
                />
              )
            })}
          </TableRow>
        )}
        <TableRow className={classnames(classes.row, classes.headerRow)}>
          {expand && (
            <TableCell
              padding="none"
              className={classnames(classes.headerCell, classes.expandHeader)}
              style={additionalStyles}
            />
          )}
          {hasBulkActions && (
            <TableCell padding="checkbox" className={classes.headerCell}>
              <Checkbox
                className="select-all"
                color="primary"
                checked={
                  selectedIds.length > 0 &&
                  all.length > 0 &&
                  all.every((id) => selectedIds.includes(id))
                }
                onChange={handleSelectAll}
                style={additionalStyles}
              />
            </TableCell>
          )}
          {Children.map(children, (field, index) =>
            isValidElement(field) ? (
              <DatagridHeaderCell
                className={classes.headerCell}
                currentSort={currentSort}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
                style={additionalStyles}
              />
            ) : null
          )}
        </TableRow>
      </TableHead>
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          isRowSelectable,
          version,
        },
        clonedChidlren
      )}
    </Table>
  )
})

export default Datagrid
