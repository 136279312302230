import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useField } from 'react-final-form'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  PasswordInput,
  SelectInput,
  FormDataConsumer,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  useTranslate,
} from 'react-admin'

import {
  resourcesByValues,
  supervisorResourceRoutesWithChildren,
  checkedResourcesByValuesIds,
} from '../../routes'

const accessRoutesChoices = () => {
  const {
    input: { onChange, value: values = [] },
  } = useField('accessRoutes')

  const translate = useTranslate()

  const manualOnChange = ({ target: { checked, id } }) => {
    let val = []

    if (checked) {
      val = [...values, id]
    } else {
      val = values.filter((el) => el !== id)
    }

    onChange(checkedResourcesByValuesIds(val))
  }

  return resourcesByValues(values).map((elmm) => {
    const elem = supervisorResourceRoutesWithChildren[elmm.id]

    let label = ''

    if (elem) {
      label =
        typeof elem === 'boolean' ? ' (Обязательный сервис)' : ' (Раздел меню)'
    }

    return (
      <FormControlLabel
        control={<Checkbox {...elmm} />}
        label={`${translate(`resources.${elmm.name}.menu`)}${label}`}
        onChange={manualOnChange}
      />
    )
  })
}

const validatePasswordConfirmation = (value, formValues) => {
  const { password } = formValues

  if (password && value && password !== value) {
    return 'Пароли не совпадают'
  }

  return required()(value)
}

const AdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      redirect="list"
      initialValues={{
        accessRoutes: ['categories', 'places', 'storage'],
        accessAllRoutes: true,
      }}
    >
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} type="email" />
      <SelectInput
        source="role"
        validate={required()}
        optionText="label"
        choices={[
          { id: 'admin', label: 'Администратор' },
          { id: 'supervisor', label: 'Менеджер' },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { role, accessAllRoutes } = formData

          return (
            <>
              {role === 'supervisor' && (
                <>
                  <ReferenceArrayInput
                    source="places"
                    reference="places"
                    {...rest}
                  >
                    <SelectArrayInput optionText="name" validate={required()} />
                  </ReferenceArrayInput>
                  <BooleanInput
                    name="accessAllRoutes"
                    label="Доступы ко всем страницам менеджера"
                  />
                  {!accessAllRoutes && accessRoutesChoices()}
                </>
              )}
            </>
          )
        }}
      </FormDataConsumer>
      <PasswordInput source="password" validate={required()} />
      <PasswordInput
        source="passwordConfirmation"
        validate={validatePasswordConfirmation}
      />
    </SimpleForm>
  </Create>
)

export default AdminCreate
