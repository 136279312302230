import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateField,
} from 'react-admin'

const PosterUsersRatingListFilters = (props) => {
  const { filterValues } = props
  const { place } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput
        source="posterUser"
        reference="poster_users"
        alwaysOn
        filter={{ checkPlace: false, place }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const PosterUsersRatingList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<PosterUsersRatingListFilters />}
      empty={false}
      sort={{ order: 'DESC', field: 'createdAtDate' }}
      perPage={50}
    >
      <Datagrid>
        <DateField source="createdAtDate" showTime />
        <TextField source="posterUser.name" label="Имя пользователя" />
        <BooleanField source="goodRateStatus" />
      </Datagrid>
    </List>
  )
}

export default PosterUsersRatingList
