import React, { useMemo } from 'react'
import {
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'
import CustomDatagrid from '../shared/CustomDatagrid'
import ExportButton from '../shared/ExportButton'
import { assignmentStatuses } from '../../i18n/assignmentStatuses'

const SalesOrdersByTypeListFilters = (props) => {
  const { filterValues = {} } = props
  const { place, dateStart, dateEnd } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <ExportButton
        alwaysOn
        fieldKey="place"
        path="sales_orders_by_type"
        filename="Sales_orders_by_type"
        {...{
          place,
          dateStart: +new Date(dateStart),
          dateEnd: +new Date(dateEnd),
          sort: 'assignment',
        }}
      />
    </Filter>
  )
}

const Empty = (props) => {
  return (
    <>
      <SalesOrdersByTypeListFilters {...props} />
      <h2>Ничего не найдено по заданным критериям</h2>
    </>
  )
}

const AssignmentFieldComponent = (props) => {
  const { record } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        assignment: assignmentStatuses[record.assignment || 'Hall'],
      }}
    />
  )
}
const SalesOrdersByTypeList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      perPage={100000}
      sort={{ order: 'DESC', field: 'assignment' }}
      filters={<SalesOrdersByTypeListFilters />}
      filterDefaultValues={filterDefaultValues}
      empty={<Empty />}
    >
      <CustomDatagrid>
        <AssignmentFieldComponent source="assignment" />
        <TextField source="count" />
      </CustomDatagrid>
    </List>
  )
}

export default SalesOrdersByTypeList
