import { getFromLocalStorage } from './localStorageHelper'

export const isAdminUser = (permissions) => {
  return permissions && permissions.role === 'admin'
}

export const placeObjectByPermissions = (permissions) => {
  if (!permissions) {
    return {}
  }

  return isAdminUser(permissions) ? {} : { place: getFromLocalStorage('place') }
}

export const placeObject = () => {
  const place = getFromLocalStorage('place')

  return place ? { place } : {}
}
