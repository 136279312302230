export const supervisorResourceRoutes = [
  'categories',
  'places',
  'storage',

  'statistics',
  'inventory',
  'inventory_ingredients',
  'inventory_ingredients_info',
  'movements',
  'daily_stats',
  'daily_stats_by_day',
  'average_by_ingredients',

  'sales',
  'order_items_type',
  'order_items_type_checks',
  'sales_order_items',
  'sales_orders_by_type',
  'sales_pizzas_by_diameters',
  'sales_orders_by_time',
  'sales_by_types_and_time',

  'all_supplies',
  'supplies',
  'suppliers',
  'supplies_orders',
  'poster_users',
  'poster_user_ratings',
]

export const supervisorResourceRoutesWithChildren = {
  categories: true,
  places: true,
  storage: true,

  statistics: [
    'inventory',
    'inventory_ingredients',
    'inventory_ingredients_info',
    'movements',
    'daily_stats',
    'daily_stats_by_day',
    'average_by_ingredients',
  ],
  sales: [
    'order_items_type',
    'order_items_type_checks',
    'sales_order_items',
    'sales_orders_by_type',
    'sales_pizzas_by_diameters',
    'sales_orders_by_time',
    'sales_by_types_and_time',
  ],
  all_supplies: ['supplies', 'suppliers', 'supplies_orders'],
}

export const resourcesByValues = (values) => {
  return supervisorResourceRoutes.map((route) => {
    const resource = supervisorResourceRoutesWithChildren[route]

    if (resource) {
      if (typeof resource === 'boolean') {
        return { id: route, name: route, disabled: true, checked: true }
      }

      if (Array.isArray(resource)) {
        return {
          id: route,
          name: route,
          disabled: true,
          checked:
            values.filter((childrenRoute) => resource.includes(childrenRoute))
              .length > 0,
        }
      }
    }

    return { id: route, name: route, checked: values.includes(route) }
  })
}

export const checkedResourcesByValuesIds = (values) => {
  return resourcesByValues(values)
    .filter((elll) => elll.checked)
    .map((ell) => ell.id)
}

export const isPermissionExist = (permissions, path) => {
  if (
    !path ||
    !permissions ||
    (permissions &&
      !permissions.accessAllRoutes &&
      !permissions.accessRoutes.includes(path))
  ) {
    return false
  }

  return true
}
