import React from 'react'

import {
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  TextInput,
} from 'react-admin'

const StorageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="storageName" />
      <TextInput source="storageAdress" />
      <NumberInput source="storageId" required disabled />
      <ReferenceInput source="place" reference="places" disabled>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
)

export default StorageEdit
